import { Component } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ProcessingBatchUtilsService } from '@shared/services/processing-batch-http.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'stiilt-export-csv-modal',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './export-csv-modal.component.html',
  styles: ``,
})
export class ExportCsvModalComponent {
  public availableFieldsToExport: string[];
  public selectedFields: string[];

  constructor(
    private readonly processingBatchUtilsService: ProcessingBatchUtilsService,
    private readonly dynamicDialogRef: DynamicDialogRef,
  ) {
    this.availableFieldsToExport = processingBatchUtilsService.fields;
    this.selectedFields = [...this.availableFieldsToExport];
  }

  public handleActionButton(): void {
    this.dynamicDialogRef.close(this.selectedFields);
  }
}
