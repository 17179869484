import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { StripeEmbeddedCheckout } from '@stripe/stripe-js';
import { TranslocoPipe } from '@jsverse/transloco';
import { StripeService } from '../../services/stripe.service';
import { AsyncPipe, NgClass, NgForOf, NgIf, TitleCasePipe } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabMenuModule } from 'primeng/tabmenu';
import { CheckboxModule } from 'primeng/checkbox';
import { CreditCardIconComponent } from '../credit-card-icon/credit-card-icon.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { from } from 'rxjs';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';

@UntilDestroy()
@Component({
  selector: 'stiilt-stripe-payment',
  standalone: true,
  imports: [
    TranslocoPipe,
    NgClass,
    NgIf,
    DropdownModule,
    FormsModule,
    TabMenuModule,
    ReactiveFormsModule,
    CheckboxModule,
    CreditCardIconComponent,
    TitleCasePipe,
    NgForOf,
    RadioButtonModule,
    ButtonModule,
    AsyncPipe,
    SpinnerComponent,
  ],
  templateUrl: './stripe-payment.component.html',
  styles: ``,
})
export class StripePayment implements OnChanges, OnDestroy {
  @Input({ required: true }) clientSecret!: string;
  @Output() paymentSuccess = new EventEmitter<void>();

  public checkout!: StripeEmbeddedCheckout;
  public isLoading: boolean = false;

  constructor(private readonly stripeService: StripeService) {}

  ngOnChanges(): void {
    if (this.clientSecret) {
      if (this.checkout) {
        this.checkout.destroy();
      }
      this.stripeService
        .init()
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.initEmbeddedCheckout();
        });
    }
  }

  ngOnDestroy(): void {
    this.checkout.destroy();
  }

  private initEmbeddedCheckout(): void {
    this.isLoading = true;
    from(
      this.stripeService.getInstance().initEmbeddedCheckout({
        onComplete: this.onPaymentComplete.bind(this),
        clientSecret: this.clientSecret,
      }),
    ).subscribe((checkout) => {
      this.checkout = checkout;
      this.checkout.mount('#checkout');
      this.isLoading = false;
    });
  }

  private onPaymentComplete(): void {
    this.paymentSuccess.emit();
  }
}
