import { Injectable } from '@angular/core';
import { EnvService } from '@core/services/env.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, EMPTY, map, Observable, ReplaySubject } from 'rxjs';
import { StaticFleet } from '@shared/models/static-fleet/static-fleet.model';
import { PaginatedDto } from '@shared/models/pagination.model';
import { StaticFleetItem } from '@shared/models/static-fleet/static-fleet-entry.model';
import { QueryFilter } from '@shared/models/query-filter.model';
import { StatusEventsService } from '@shared/services/status-events.service';
import { ToastService } from '@shared/services/toast.service';
import { TranslocoService } from '@jsverse/transloco';
import { StaticFleetColumnMapping } from '@shared/models/static-fleet/static-fleet-column-mapping.model';

@Injectable({
  providedIn: 'root',
})
export class StaticFleetHttpService {
  private staticFleet = new ReplaySubject<PaginatedDto<StaticFleetItem>>(1);
  public staticFleet$ = this.staticFleet.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvService,
    private readonly statusEventService: StatusEventsService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslocoService,
  ) {}

  public patchStaticFleet(fleetOwnerId: number, payload: StaticFleet): Observable<void> {
    return this.http
      .post<void>(`${this.env.baseUrl}/static-fleet`, payload)
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getAllPaginatedForUser(query: QueryFilter<StaticFleetItem>): void {
    this.http
      .get<PaginatedDto<StaticFleetItem>>(`${this.env.baseUrl}/static-fleet`, {
        params: { ...query },
      })
      .pipe(
        map((response: PaginatedDto<StaticFleetItem>) => {
          this.staticFleet.next(response);
        }),
        catchError(this.handleError.bind(this)),
      )
      .subscribe();
  }

  public getStaticFleetCsvHeaders(): Observable<StaticFleetColumnMapping> {
    return this.http
      .get<StaticFleetColumnMapping>(`${this.env.baseUrl}/static-fleet/header-mappings`, {})
      .pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(error: HttpErrorResponse, _: any, errorMessage = ''): Observable<never> {
    switch (error?.error?.errorCode) {
      default: {
        this.statusEventService.setHttpStatus(false);
        this.toastService.error(this.translateService.translate('app.errors.generic_network_error'));
        return EMPTY;
      }
    }
    return EMPTY;
  }
}
