import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoPipe } from '@jsverse/transloco';
import { TranslocoDatePipe } from '@jsverse/transloco-locale';
import { PlanChooserComponent } from '@shared/components/plan-chooser/plan-chooser.component';
import { SpinnerModule } from 'primeng/spinner';
import { ToastModule } from 'primeng/toast';
import { AlertComponent } from './components/alert/alert.component';
import { AppIconComponent } from './components/app-icon/app-icon.component';
import { BatchDetailComponent } from '@shared/components/batch-list/batch-detail/batch-detail.component';
import { StiiltCalendarComponent } from './components/calendar/stiilt-calendar.component';
import { StripePayment } from './components/card-payment/stripe-payment.component';
import { CardComponent } from './components/card/card.component';
import { ColorBadgeStatusComponent } from './components/color-badge-status/color-badge-status.component';
import { CreditCardIconComponent } from './components/credit-card-icon/credit-card-icon.component';
import { DialogComponent } from './components/dialog-wrapper/dialog.component';
import { EmbeddedPdfViewerComponent } from './components/embedded-pdf-viewer/embedded-pdf-viewer.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { InvisibleStepperComponent } from './components/invisible-stepper/invisible-stepper.component';
import { StiiltStepComponent } from './components/invisible-stepper/stiilt-step/stiilt-step.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { StatusIndicatorComponent } from './components/status-indicator/status-indicator.component';
import { UpdateStaticFleetComponent } from './components/steps/update-static-fleet/update-static-fleet.component';
import { UploadParkingTicketsComponent } from './components/steps/upload-parking-tickets/upload-parking-tickets.component';
import { TitleComponent } from './components/stiilt-title/title.component';
import { ToastComponent } from './components/toast/toast.component';
import { ToggleMenuComponent } from './components/toggle-menu/toggle-menu.component';
import { UploadComponent } from './components/upload/upload.component';
import { CursorPointerClickDirective } from './directives/cursor-pointer-click.directive';
import { CustomRouterLinkActiveDirective } from './directives/custom-router-link-active.directive';
import { PlatformDependantRouterLinkDirective } from './directives/platform-dependant-router-link.directive';
import { StiiltTemplateDirective } from './directives/stiilt-template.directive';
import { NotSpecifiedPipe } from './pipes/not-specified.pipe';
import { PrimengModule } from './primeng.module';
import {
  GooglePlacesAutocompleteDirective
} from '@shared/components/google-places-autocomplete/google-places-autocomplete.directive';
import { StiiltPhoneInputComponent } from './components/stiilt-phone-input/stiilt-phone-input.component';

const sharedModule = [
  TranslocoModule,
  TranslocoPipe,
  TranslocoDatePipe,
  CommonModule,
  PrimengModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  SpinnerModule,
  NgOptimizedImage,
  ToastModule,
];
const customModules = [
  CardComponent,
  TitleComponent,
  UploadComponent,
  AlertComponent,
  StiiltStepComponent,
  InvisibleStepperComponent,
  UpdateStaticFleetComponent,
  UploadParkingTicketsComponent,
  EmptyStateComponent,
  StatusIndicatorComponent,
  SpinnerComponent,
  ToggleMenuComponent,
  EmbeddedPdfViewerComponent,
  DialogComponent,
  ToastComponent,
  AppIconComponent,
  ColorBadgeStatusComponent,
  StiiltCalendarComponent,
  StripePayment,
  CreditCardIconComponent,
  PlanChooserComponent,
  ToastComponent,
  BatchDetailComponent,
  StiiltPhoneInputComponent,
];

const pipesAndDirectives = [
  PlatformDependantRouterLinkDirective,
  CustomRouterLinkActiveDirective,
  CursorPointerClickDirective,
  GooglePlacesAutocompleteDirective
];

const standalonePipesAndDirectives = [NotSpecifiedPipe, StiiltTemplateDirective];

@NgModule({
  declarations: [...pipesAndDirectives],
  imports: [...sharedModule, ...customModules, ...standalonePipesAndDirectives],
  exports: [...sharedModule, ...customModules, ...pipesAndDirectives, ...standalonePipesAndDirectives],
})
export class SharedModule {}
