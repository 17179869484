import { formatDate } from '@angular/common';
import moment from 'moment';
import Diff = moment.unitOfTime.Diff;

export class DateUtils {
  public static readonly dateOnlyIsoFormat = 'yyyy-MM-dd';

  public static readonly hours: Diff = 'hours';

  public static readonly minutes: Diff = 'minutes';

  public static readonly seconds: Diff = 'seconds';
  public static readonly locale = 'en-US';

  public static toApiDate(date: Date): string | null {
    return date ? formatDate(date, DateUtils.dateOnlyIsoFormat, DateUtils.locale) : null;
  }
}
