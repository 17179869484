import { AsyncPipe, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoPipe } from '@jsverse/transloco';
import { Papa, ParseResult } from 'ngx-papaparse';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { catchError } from 'rxjs';
import { StaticFleetItem } from '@shared/models/static-fleet/static-fleet-entry.model';
import { StatusEventsService } from '@shared/services/status-events.service';
import { AlertComponent, AlertType } from '../../alert/alert.component';
import { TitleComponent } from '../../stiilt-title/title.component';
import { UploadComponent, UploadType } from '../../upload/upload.component';
import { requiredField } from './constants/required_fields';
import { StaticFleetHttpService } from './services/static-fleet-http.service';
import { StaticFleetColumnMapping } from '@shared/models/static-fleet/static-fleet-column-mapping.model';

@Component({
  selector: 'stiilt-update-static-fleet',
  standalone: true,
  imports: [
    AlertComponent,
    UploadComponent,
    TitleComponent,
    ButtonModule,
    TranslocoPipe,
    NgIf,
    CheckboxModule,
    FormsModule,
    AsyncPipe,
  ],
  templateUrl: './update-static-fleet.component.html',
})
export class UpdateStaticFleetComponent implements OnInit {
  @Output('nextStepClicked') private nextStepEmitter = new EventEmitter<void>();
  @Input() updateFleetOnly: boolean = false;

  protected readonly UploadType = UploadType;
  protected readonly AlertType = AlertType;

  public selectedFile: File | undefined;
  public isMissingMandatoryField: boolean = false;
  public parsingError: boolean = false;
  public canContinue: boolean = false;
  private parsedData!: StaticFleetItem[];
  public skipUpdate: boolean = false;
  private customMappings?: StaticFleetColumnMapping;

  constructor(
    private readonly papa: Papa,
    private readonly staticFleetHttpService: StaticFleetHttpService,
    private readonly http: HttpClient,
    public readonly statusEventService: StatusEventsService,
  ) {}

  ngOnInit(): void {
    this.staticFleetHttpService.getStaticFleetCsvHeaders().subscribe((headers) => {
      this.customMappings = headers;
    });
  }

  public handleFilesAdded(event: { files: File[]; numberOfFiles: number }): void {
    if (event.numberOfFiles > 0) {
      this.skipUpdate = false;
      this.selectedFile = event.files[0];
      this.resetErrors();
      this.papa.parse(this.selectedFile, {
        header: true,
        complete: this.onComplete.bind(this),
        error: this.onError.bind(this),
        transformHeader: this.getKeyByValue.bind(this),
        encoding: 'UTF-8',
      });
    } else {
      this.selectedFile = undefined;
      this.canContinue = false;
    }
  }

  private getKeyByValue(header: string): string {
    if (this.customMappings) {
      for (const [key, value] of Object.entries(this.customMappings)) {
        if (value === header) {
          return key;
        }
      }
      return header;
    } else {
      return header;
    }
  }

  private onComplete(results: ParseResult<StaticFleetItem[]>, file?: File) {
    if (results.errors.length) {
      this.parsingError = true;
    } else {
      this.isMissingMandatoryField = !requiredField.every((field) => results.meta.fields.includes(field));
      this.canContinue = !this.isMissingMandatoryField;
      if (this.canContinue) {
        this.parsedData = results.data;
      }
    }
  }

  private onError(error: any, file: any): void {
    console.error('An error occurred while parsing the file', error);
  }

  public patchStaticFleet(): void {
    if (this.skipUpdate) {
      this.nextStepEmitter.emit();
      return;
    }
    this.statusEventService.setHttpStatus(true);
    this.staticFleetHttpService
      .patchStaticFleet(1, {
        staticFleet: this.parsedData,
      })
      .pipe(
        catchError((error) => {
          return error;
        }),
      )
      .subscribe(() => {
        this.nextStepEmitter.emit();
      });
  }

  private resetErrors(): void {
    this.isMissingMandatoryField = false;
    this.parsingError = false;
  }

  public downloadFile(): void {
    this.http.get('assets/files/modele_export.xlsx', { responseType: 'blob' }).subscribe((blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'modele_export.xlsx';
      link.click();
      window.URL.revokeObjectURL(url);
    });
  }
}
