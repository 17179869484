import { Component, Input } from '@angular/core';
import { NgIf, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'stiilt-app-icon',
  standalone: true,
  imports: [NgOptimizedImage, NgIf],
  templateUrl: './app-icon.component.html',
  styles: ``,
})
export class AppIconComponent {
  @Input() iconOnly: boolean = false;
}
