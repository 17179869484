import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EnvService } from '@core/services/env.service';
import { StatusEventsService } from '@shared/services/status-events.service';
import { ToastService } from '@shared/services/toast.service';
import { catchError, EMPTY, map, Observable, ReplaySubject } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';
import { PaymentPlan } from '@shared/models/payment-plan/payment-plan.model';

@Injectable({
  providedIn: 'root',
})
export class PlanHttpServiceService {
  private existingPlans = new ReplaySubject<PaymentPlan[]>(1);
  public existingPlans$ = this.existingPlans.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvService,
    private readonly statusEventService: StatusEventsService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslocoService,
  ) {}

  public getPlans(): void {
    this.http
      .get<PaymentPlan[]>(`${this.env.baseUrl}/payments/plans`)
      .pipe(
        map((response: PaymentPlan[]) => {
          this.existingPlans.next(response);
        }),
        catchError(this.handleError.bind(this)),
      )
      .subscribe();
  }

  public getPlanById(id: number): void {
    this.http
      .get<PaymentPlan>(`${this.env.baseUrl}/payments/plans/${id}`)
      .pipe(
        map((response) => {
          this.existingPlans.next([response]);
        }),
        catchError(this.handleError.bind(this)),
      )
      .subscribe();
  }

  private handleError(error: HttpErrorResponse, _: any, errorMessage = ''): Observable<never> {
    switch (error?.error?.errorCode) {
      default: {
        this.statusEventService.setHttpStatus(false);
        this.toastService.error(this.translateService.translate('app.errors.generic_network_error'));
        return EMPTY;
      }
    }
    return EMPTY;
  }
}
